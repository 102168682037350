import React from "react";
import { Link } from "gatsby";
import { Layout } from "StaticPages/Layouts";
import Helmet from "react-helmet";

export default () => {
    return <>
        <Helmet title="Q＆A | VISUALIZ INC" />
        <Layout>
            <main className="QandA">
                <div className="BreadClumb TopBar" id="head">
                    <p><Link to="/">VISUALIZ inc</Link>＞<Link to="/service">サービス</Link>＞<span>Q＆A</span></p>
                </div>
                <h2 className="main-subttl LSize">Q ＆ A</h2>
                <p className="main_about LSize Btm190">
                    DATAサービスについて、よくある質問にお答えいたします。</p>

                <article>
                    <p>coming soon</p>
                </article>
            </main>
        </Layout>
    </>;
};